import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";

import moment from "moment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Badge from "@material-ui/core/Badge";
import Tooltip from "@material-ui/core/Tooltip";

import OrderRow from "./OrderRow";
import AmountsCount from "./AmountsCount";

moment.locale("pl");

class OrdersTable extends React.Component {
  render() {
    const { classes, orders, startDate } = this.props;
    let previousDate = startDate
      ? moment(startDate).format("YYYY-MM-DD")
      : null;

    if (
      orders.length &&
      moment(orders[orders.length - 1].when).format("YYYY-MM-DD") !==
        moment(startDate).format("YYYY-MM-DD")
    ) {
      previousDate = moment(startDate).add(-1, "day");
    }

    let unconfirmedCount = orders.filter((el) => !el.confirmed);
    unconfirmedCount = unconfirmedCount.length;

    let withoutDrivers = orders.filter(
      (el) => el.status === "active" && !el.DriverId && !el.driverNumber
    );
    withoutDrivers = withoutDrivers.length;

    return (
      <Paper className={classes.root}>
        <Table padding="none" size="small" className="ordersTable">
          <TableHead>
            <TableRow>
              <TableCell align="center">Godzina</TableCell>
              <TableCell align="center">Czas (min)</TableCell>
              <TableCell align="center">Trasa</TableCell>
              <TableCell align="center">Pasażer</TableCell>
              <TableCell align="center">Ilość osób</TableCell>
              <TableCell align="center">Kierowca</TableCell>
              <TableCell align="center">Nr. Taxi</TableCell>
              <TableCell align="center">CC MPK Karta</TableCell>
              <TableCell align="center">Kwota Firma</TableCell>
              <TableCell align="center">Kwota Pasażer</TableCell>

              <TableCell align="center">Zamawiający</TableCell>
              <TableCell align="center">nr. Lotu</TableCell>
              <TableCell align="center" style={{ width: "200px" }}>
                Uwagi ogólne
              </TableCell>
              <TableCell align="center">Uwagi wewnętrzne</TableCell>
              <TableCell align="center">
                {unconfirmedCount ? (
                  <Tooltip title="Niepotwierdzone" placement="top">
                    <Badge color="secondary" badgeContent={unconfirmedCount}>
                      &nbsp;
                    </Badge>
                  </Tooltip>
                ) : null}
                {withoutDrivers ? (
                  <Tooltip
                    style={{ marginLeft: "20px" }}
                    title="Bez kierowców"
                    placement="top"
                  >
                    <Badge color="primary" badgeContent={withoutDrivers}>
                      &nbsp;
                    </Badge>
                  </Tooltip>
                ) : null}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order) => {
              let ret = [];
              let currentDate = moment(order.when).format("YYYY-MM-DD");

              if (previousDate && previousDate !== currentDate) {
                ret.push(
                  <TableRow key={"dateSratata" + order.id}>
                    <TableCell
                      colSpan="20"
                      style={{
                        padding: "0 14px",
                        fontSize: "12px",
                        fontWeight: "bold",
                        backgroundColor: "#f5f5f5",
                        color: "#2196f3",
                      }}
                    >
                      {moment(currentDate).format("dddd, D MMMM YYYY")}
                    </TableCell>
                  </TableRow>
                );
              }
              previousDate = currentDate;

              if (this.props.showEditor && order.editor) {
                ret.push(
                  <tr key={"orderroweditor" + order.id}>
                    <td
                      colSpan="14"
                      style={{
                        padding: "0 14px",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      {order.originalUpdatedAt
                        ? moment(order.originalUpdatedAt).format(
                            "YYYY-MM-DD, HH:mm:ss"
                          )
                        : moment(order.updatedAt).format(
                            "YYYY-MM-DD, HH:mm:ss"
                          )}{" "}
                      - {order.editor.name}
                    </td>
                  </tr>
                );
              }
              ret.push(
                <OrderRow
                  key={"orderrow" + order.id}
                  data={order}
                  disableEditing={this.props.disableEditing}
                />
              );
              return ret;
            })}
            {!this.props.disableEditing ? (
              <AmountsCount orders={orders} />
            ) : null}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    overflow: "visible",
  },
});

export default withStyles(styles)(withSnackbar(OrdersTable));
